export default class ApiService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  getFullURL(path) {
    return this.baseUrl + path;
  }

  request = async (method, path, additional) => {
    let url = this.getFullURL(path);
    const { data, params, headers } = additional;
    if (params) {
      const query = Object.keys(params)
        .map(
          key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        )
        .join('&');
      url = url + '?' + query;
    }
    const requestHeaders = new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...headers,
    });
    const request = {
      mode: 'cors',
      method: method,
    };
    if (requestHeaders) {
      request.headers = requestHeaders;
    }
    if (data) {
      request.body = JSON.stringify(data);
    }
    const response = await fetch(url, request);
    let jsonData = await response.json();
    if (!response.ok) {
      const err = new Error(response.statusText);
      err.status = response.status;
      err.statusText = response.statusText;
      err.jsonData = jsonData;
      throw err;
    }
    return jsonData;
  };

  get = async (path, additional) => {
    return await this.request('GET', path, additional);
  };

  delete = async (path, additional) => {
    return await this.request('DELETE', path, additional);
  };

  post = async (path, data, additional) => {
    return await this.request('POST', path, { data, ...additional });
  };

  put = async (path, data, additional) => {
    return await this.request('PUT', path, { data, ...additional });
  };
}
