import React, { Component } from 'react';
import { exposeMetrics } from 'react-metrics';
import queryString from 'query-string';
import ZipContext from 'components/Shared/ZipContext';
import { PropTypes as MetricsPropTypes } from 'react-metrics';
import IPApi from 'components/Shared/IPApi';
import {
  getZip,
  setZip,
  ZipForm,
  RedirectModal,
} from 'components/ZipGateContainer/ZipGateContainer';
import { withZipGateData } from 'components/ZipGateContainer/ZipGateDataHOC';

export const getHasFullAccess = () => {
  return localStorage.getItem('full-access') === '1';
};

export const setHasFullAccess = flag => {
  const value = flag ? '1' : '0';
  return localStorage.setItem('full-access', value);
};

export class IPGateContainer extends Component {
  constructor() {
    super();
    this.state = {
      mounted: false,
      showZipGate: false,
      shouldRedirect: false,
    };
  }

  async componentDidMount() {
    const { data } = this.props;
    this.setState({ mounted: true });
    const storedZipInfo = getZip();
    if (storedZipInfo) {
      const { zip, club } = storedZipInfo;
      return this.handleZip({ zip, club, source: 'cookie' });
    }

    try {
      const ipInfo = await IPApi.getIpInfo();
      const zip = parseInt(ipInfo.services.geo.ip.postalCode);
      const club = parseInt(ipInfo.services.geo.ip.clubs.club[0].code);
      if (data.allowedZips.zips.indexOf(zip) !== -1) {
        return this.handleZip({ zip, club, source: 'ip' });
      } else {
        this.setState({ showZipGate: true });
      }
    } catch (err) {
      this.setState({ showZipGate: true });
    }
  }

  handleZip = ({ zip, club, source }) => {
    const { data, zipContext } = this.props;
    setZip({ zip, club });
    zipContext.setZip({ zip, club });
    if (data.allowedClubs.clubs.indexOf(club) !== -1) {
      setHasFullAccess(data.allowedZips.zips.indexOf(zip) !== -1);
      this.props.metrics.track('in_market', {
        zipCode: zip,
        label: source,
        nonInteraction: 1,
      });
      this.setState({ showZipGate: false });
    } else {
      setHasFullAccess(false);
      this.setState({ showZipGate: false, shouldRedirect: true });
    }
  };

  performRedirect = () => {
    const { data } = this.props;
    const { zip, club } = getZip();
    const url = `${data.redirectURL}?${queryString.stringify({
      zip,
      club,
    })}`;
    window.location.replace(url);
  };

  render() {
    const { mounted, showZipGate, shouldRedirect } = this.state;
    const { data, location } = this.props;

    if (!mounted) {
      return null;
    }

    if (shouldRedirect) {
      return (
        <RedirectModal
          timeout={5000}
          onClose={this.performRedirect}
          logo={data.logo.publicURL}
          message={data.redirectMessage}
        />
      );
    }

    if (!showZipGate) {
      return null;
    }

    return (
      <ZipForm
        data={data}
        location={location}
        callback={({ zip, club }) => {
          this.handleZip({ zip, club, source: 'ipcheck_form' });
        }}
      />
    );
  }
}

class CombinedContext extends Component {
  constructor() {
    super();
  }

  static contextTypes = {
    metrics: MetricsPropTypes.metrics,
  };

  render() {
    return (
      <ZipContext.Consumer>
        {context1 => <IPGateContainer {...this.props} zipContext={context1} />}
      </ZipContext.Consumer>
    );
  }
}

export default withZipGateData(exposeMetrics(CombinedContext));
