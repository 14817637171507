import { isValidNumber } from 'libphonenumber-js';
import { ZIP_LENGTH } from './FormField/FormField';

export const email = messages => {
  return value =>
    value && !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z0-9]{2,}$/i.test(value)
      ? (messages && messages.default) || 'Email not valid'
      : undefined;
};

export const number = messages => {
  return value =>
    isNaN(value)
      ? (messages && messages.default) || 'Must be a number'
      : undefined;
};

export const phone = messages => {
  return value =>
    value && !isValidNumber(value)
      ? (messages && messages.default) || 'Phone number is not valid'
      : undefined;
};

export const required = messages => {
  return value =>
    value
      ? undefined
      : (messages && messages.default) || 'This field is required';
};

export const text = message => {
  return value =>
    value && !/[\w-.,'\\/\s]$/i.test(value)
      ? (message && message.default) ||
        "Can contain letters, digits, spaces and symbols (- . , ' \\ /)"
      : undefined;
};

export const zip = messages => {
  const errors = {
    required: 'Required',
    invalid: 'Invalid zip',
    ...messages,
  };
  return value => {
    if (!value) {
      return errors.required;
    } else if (value.length < ZIP_LENGTH) {
      return errors.invalid;
    }
  };
};

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const prepareErrors = errors => {
  const messages = errors.reduce((obj, err) => {
    const parts = err.key.split('.');
    if (parts.length > 1) {
      obj[parts[0]] = {
        ...obj[parts[0]],
        [parts[1]]: err.value,
      };
    } else {
      obj[parts[0]] = {
        ...obj[parts[0]],
        default: err.value,
      };
    }
    return obj;
  }, {});
  return messages;
};

export const Validators = {
  email: email,
  number: number,
  phone: phone,
  required: required,
  text: text,
  zip: zip,
};

export default Validators;
