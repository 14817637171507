import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';

import './ModalWindow.scss';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

class ModalWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open || false,
    };
  }

  close = () => this.setState({ open: false });

  open = () => {
    this.setState({
      open: true,
    });
  };

  close = () => {
    this.setState({
      open: false,
    });
  };

  componentDidMount() {
    const { timeout, onClose } = this.props;
    if (timeout) {
      setTimeout(() => {
        this.close();
        if (onClose) {
          onClose();
        }
      }, timeout);
    }
  }

  render() {
    const { children } = this.props;
    return (
      <Fragment>
        {this.state.open && (
          <Modal>
            <div className="aaa-modal-window__dimmer">
              <div className="aaa-modal-window__container">
                {this.props.render({ close: this.close })}
              </div>
            </div>
          </Modal>
        )}
        {children({ open: this.open })}
      </Fragment>
    );
  }
}

export { Modal };
export default ModalWindow;
